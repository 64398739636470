<template>

  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none ml-10" id="profile-tabs"
           :key="isSmallerScreen">
    <!-- GENERAL -->
    <a href="#"  ref="asd"></a>
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'General' : ''">
      <div class="tab-general p-0 w-auto mr-10" >
        <user-settings-general :api-route="true"/>
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Change Password' : ''">
      <div class="tab-change-pwd p-0 w-auto mr-10">
        <user-settings-change-password :api-route="true"/>
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
  import UserSettingsGeneral from "../../admin/profile/UserSettingsGeneral";
  import UserSettingsChangePassword from "../../admin/profile/UserSettingsChangePassword";


  export default {
    components: {
      UserSettingsGeneral,
      UserSettingsChangePassword,
    },
    data() {
      return {}
    },
    mounted() {
      this.$refs.asd.click();
    },

    computed: {
      isSmallerScreen() {
        return this.$store.state.windowWidth < 768
      }
    }
  }
</script>

<style lang="scss">
  #profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
  .line-vs-tabs{
    display: none;
  }
  .con-ul-tabs {
    padding-right: 20px !important;
    .ul-tabs {
      border-right: none;
    }
    .vs-tabs--li.activeChild {
      .vs-tabs--btn {
        padding: 0 !important;
        span {
          top: -1px;
          padding: 0 0 0 5px;
        }
        &:after {
          display: none !important;
        }
      }
    }
    .vs-tabs--btn {
      padding: 0 !important;
    }
  }
}

.vs-con-input-label.is-label-placeholder.isFocus
.vs-input--placeholder,
.vs-input--input.hasIcon:focus+.vs-input--placeholder,
.vs-input--input.hasValue+.vs-placeholder-label,
.vs-input--input:focus+.vs-placeholder-label {
  padding-left: 10px!important;
}
</style>
